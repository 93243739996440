<template>
  <div class="faq">
    <v-btn v-if="timelineTitle" class="my-8" disabled large rounded depressed>{{
      timelineTitle
    }}</v-btn>
    <template>
      <v-timeline v-if="timelineItems && timelineItems.length">
        <v-timeline-item
          v-for="n in 1"
          :key="n + '' + Math.random()"
          color="grey"
          fill-dot
          left
          small
        >
          <v-card outlined color="grey lighten-3" class="elevation-0">
            <v-card-text class="faq-answer">
              <div v-if="subtitle" class="faq-answer-dark">
                {{ subtitle }}
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item
          v-for="(item, index) in timelineItems"
          :key="index + '' + Math.random()"
          class="align-center"
          :color="item.color"
          fill-dot
          right
          small
        >
          <template #opposite>
            <h5 class="">{{ item.question }}</h5>
          </template>
          <v-card :color="item.color" dark outlined class="elevation-0">
            <v-card-text class="faq-answer">
              <h4 class="white--text">
                {{ answerValue(item.answer) }}
              </h4>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </template>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    timelineTitle: {
      type: String,
      default: 'Timeline'
    },
    timelineItems: {
      type: Array,
      default: () => {}
    },
    subtitle: {
      type: String,
      default: 'This is Subtitle'
    },
    adkType: {
      type: String,
      default: ''
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const answerValue = val => {
      return adkData.value?.fields?.find(f => f.model === val)?.value[val] || '';
    };

    return { programDoc, adkData, answerValue };
  }
};
</script>

<style lang="scss" scoped>
.faq {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  max-width: 90%;
  margin: auto;
  line-height: 30px;
}
.faq-chat {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 5%;
}
.faq-chat-line {
  margin: 5px;
}
.faq-avatar {
  margin: 5px;
}
.faq-question {
  // text-align: left;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 700;
  color: #404142;
}
.faq-answer {
  text-align: left;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  color: white;
  font-style: italic;
}
.faq-answer-dark {
  text-align: left;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #404142;
  font-style: italic;
}
.faq-answer-dark-highlight {
  text-align: left;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 0px;
  color: #404142;
}
.v-timeline-item__divider {
  margin-top: auto;
  margin-bottom: auto;
}
.demoClass {
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    height: fit-content !important;
    min-height: fit-content !important;
  }
  .v-item-group {
    .v-chip {
      @media (max-width: 767px) {
        height: fit-content;
        padding: 6px 12px;
        .v-chip__content {
          white-space: normal;
        }
      }
    }
  }
}
.resources-item {
  .v-list-item {
    justify-content: center;
  }
}
</style>
